<template>
  <div
    class="uk-flex uk-flex-center uk-flex-middle uk-background-cover"
    uk-height-viewport
    v-bind:style="{ backgroundImage: 'url(' + api + '/storefronts/' + storefrontId + '/background-login' + ')' }"
    
  >
    <div>
      <div
        class="
          uk-card uk-border-rounded uk-width-medium uk-padding
        " :style="'background-color:' + storefrontStorefront.login_color_box + '!important'"
        
      >
        <div class="uk-text-center">
          <img :src="api + '/storefronts/' + storefrontId + '/logo'" alt />
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider name="email" rules="required|email">
            <input
              test="email-input"
              class="uk-margin-top uk-input uk-text-center uk-border-rounded"
              type="text"
              v-model="model.email"
              placeholder="correo"
              name="email"
              @keydown="keydownHandler"
            />
          </ValidationProvider>

          <ValidationProvider name="password" rules="required">
            <input
              test="password-input"
              class="uk-margin-top uk-input uk-text-center uk-border-rounded"
              type="password"
              v-model="model.password"
              placeholder="contraseña"
              name="password"
              @keydown="keydownHandler"
            />
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              test="login-button"
              class="uk-button uk-button-primary uk-border-rounded"
              @click="login(model)"
              :disabled="invalid"
            >
              Ingresar
            </button>
          </div>
        </ValidationObserver>
        <div class="uk-text-center uk-margin-top">
          <router-link
            to="/forgot-password"
            class="uk-link-reset uk-font-underline"
          >
            Olvidé mi contraseña
          </router-link>
        </div>
      </div>
      <div
        class="uk-text-center uk-margin-top"
        v-if="
          !storefrontIsLoadingStorefront &&
          storefrontStorefront.public_registration
        "
      >
        <router-link to="/register" class="uk-link-reset uk-font-underline">
          Crear nueva cuenta
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AuthLogin",

  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      backgrounds: [],
    };
  },

  computed: {
    ...mapGetters([
      "authIsLogingIn",
      "storefrontStorefront",
      "storefrontIsLoadingStorefront",
    ]),
  },

  methods: {
    ...mapActions(["login"]),
    keydownHandler(event) {
      switch (event.key) {
        case "Enter":
          event.preventDefault();
          this.login(this.model);
      }
    },
  },
};
</script>
